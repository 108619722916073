*{
  --main-color: #FCFCFF;
  background-color: var(--main-color);

  --button-color: #22dcc3;

  --border-color: #808080;

  --text-color: #00001A;
  color: var(--text-color);

  margin: 0;
  padding: 0;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.bordered{
  border: solid 1px var(--border-color);
  background-color: var(--main-color);
}
a{
  text-decoration: none;
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromDown {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}
.animated-left {
  animation: slideInFromLeft 1s ease; /* Změňte ease na jinou timing funkci podle vašich preferencí */
}

.animated-right {
  animation: slideInFromRight 1s ease; /* Změňte ease na jinou timing funkci podle vašich preferencí */
}
.animated-down {
  animation: slideInFromDown 1s ease; /* Změňte ease na jinou timing funkci podle vašich preferencí */
}
.animate {
  opacity: 1; /* Při přidání třídy 'animate' změníme průhlednost na 1, čímž prvek zviditelníme */
  transform: translateX(0); /* Při přidání třídy 'animate' vrátíme prvek na jeho původní pozici */
}
h1, h2, h3, h4, p {
  margin: 0;
  padding: 5px;
}
.section{
  display: flex;
  flex-direction: row;
  padding: 40px 100px;
  overflow: hidden;
}
.section div   {
  margin: 20px;
  width: 50%;
}
.a{
  color: var(--button-color);
  font-weight: bold;
}

@media only screen and (max-width: 700px) {
  .section{
    flex-direction: column;
    padding: 10px 20px;
  }
  .section div   {
    margin: 20px auto;
    width: 100%;
  }
  .section div img{
    width: 100%;
  }
  .img1{
    display: none;
  }
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--button-color);
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1af0d4;
}